
























































import { Component, Provide, Vue } from "vue-property-decorator";
import {
  ApiPostDoLogin,
  ApiPostVerifyCode,
  ApiAddLoginDevice,
  ApiPostDoLoginNoAuth,
} from "@/apis";
import * as utils from "@/utils";
import { AppModule } from "@/store/modules/app";
import { breadcrumb } from "@/utils/breadcrumb";
import Verify from "@/components/verifition/Verify.vue";
import * as isLiu from "@/utils/judgeEnv";

@Component({
  name: "Login",
  components: {
    Verify,
  },
})
export default class Login extends Vue {
  captchaImg = "";
  captchaCode = "";
  @Provide() userName = "";
  @Provide() pwd = "";
  @Provide() picCode = "";
  @Provide() autoLoginChecked = false;
  env = process.env.NODE_ENV;

  created() {
    if (isLiu.judgeEnv()) {
      this.$router.push("/login_liu");
    }
  }

  async mounted() {
    await this.requestCaptcha();
    sessionStorage.clear();
  }
  //密码输入框
  isPassword = true;
  changePasswordState() {
    this.isPassword = !this.isPassword;
  }

  async requestCaptcha() {
    try {
      const res = await ApiPostVerifyCode();
      if (res.success) {
        utils.set("REQUEST-AUTH", res.data.auth);
        utils.set("REQUEST-JSESSIONID", res.data.jsessionid);
        this.captchaImg = "data:image/jpg;base64," + res.data.verify_code;
      }
    } catch (e) {
      console.log(e);
    }
  }

  refreshCaptcha() {
    this.requestCaptcha();
  }

  trim(val: string) {
    const reg = /(^\s*)|(\s*$)/g;
    return val.replace(reg, "");
  }

  checkPrama() {
    if (!this.userName) {
      this.$message.error("用户名不能为空！");
      return;
    } else if (!this.pwd) {
      this.$message.error("密码不能为空！");
      return;
    }
    (this.$refs.verify as any).show();
  }

  async login(params: any) {
    if (this.userName && this.pwd) {
      try {
        const captchaVerification = params.captchaVerification;
        const encryptPwd = await utils.encrypt(this.pwd);
        const result = await ApiPostDoLogin(
          this.trim(this.userName),
          encryptPwd,
          captchaVerification,
          utils.get("REQUEST-JSESSIONID")
        );
        if (result.success) {
          utils.set("auth", result.data.token);
          utils.set("REQUEST-AUTH", "");
          utils.set("doctorLoginInfo", result.data.user_login_info);
          utils.set("userId", result.data.user_id);
          if (result.data.user_login_info.username) {
            utils.set("userName", result.data.user_login_info.username);
          }
          if (result.data.user_login_info.avater) {
            utils.set("headIcon", result.data.user_login_info.avater);
          }
          //调用董哥接口
          this.addLoginDevice(result.data.id);
          //跳转到主页
          this.didLoginSuccess();
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      if (!this.userName) {
        this.$message.error("用户名不能为空！");
      } else if (!this.pwd) {
        this.$message.error("密码不能为空！");
      }
    }
  }

  async loginOld() {
    if (this.userName && this.pwd) {
      try {
        const encryptPwd = await utils.encrypt(this.pwd);
        const result = await ApiPostDoLoginNoAuth(
          this.trim(this.userName),
          encryptPwd,
          // "123456",
          utils.get("REQUEST-JSESSIONID")
        );
        if (result.success) {
          utils.set("auth", result.data.auth);
          utils.set("REQUEST-AUTH", "");
          utils.set("doctorLoginInfo", result.data);
          if (result.data.name) {
            utils.set("userName", result.data.name);
          }
          if (result.data.avater) {
            utils.set("headIcon", result.data.avater);
          }
          AppModule.ToggleFunctionGroupArray(result.data.menu_groups);
          //调用董哥接口
          this.addLoginDevice(result.data.id);
          //跳转到主页
          this.didLoginSuccess();
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      if (!this.userName) {
        this.$message.error("用户名不能为空！");
      } else if (!this.pwd) {
        this.$message.error("密码不能为空！");
      }
    }
  }
  addLoginDevice(id: string) {
    const param = {
      user_id: id,
      device_module: 5,
    };
    ApiAddLoginDevice(param).then((res) => {
      if (res.success) {
        res;
      }
    });
  }
  didLoginSuccess() {
    breadcrumb.push(this, "/home", "首页", {}, true);
  }
}
